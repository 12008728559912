/**
 * The Loading component, used while resources load
 * Keep this component as similar as possible to the resource page importing it
 */
import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import { Link } from "react-router-dom";
import FormattedNumber from "../FormattedNumber";
import ResponsivePicture from "../ResponsivePicture";
import LazyLoad from "react-lazyload";
import { REACT_APP_SHOP_CDN_SERVER } from "../../utils/env";
import { useDispatch } from "react-redux";
const ShopProductCard = (props, context) => {
  const {
    language,
    product,
    navigateTo,
    titleTag,
    onClickOverwrite,
    setSelected,
    selected,
    closeSearch,
  } = props;

  const dispatch = useDispatch();
  const handleProductClick = (event) => {
    event?.preventDefault();
    if (!!product.external) {
      window.location.href = product.external;
    } else {
      console.log("else");
      if (product && product.attributes) {
        dispatch(navigateTo(`/${language}/i/${product.attributes.slug}/`));
        if (closeSearch) closeSearch();
      } else dispatch(navigateTo(`/${language}/i/${product.id}/`));
    }
  };

  const handleProductClickContactForm = (selected) => {
    setSelected(selected);
    if (onClickOverwrite) onClickOverwrite(product.id);
  };

  // FIXME should not be localhost but from envs!
  const SHOP_API_URL = REACT_APP_SHOP_CDN_SERVER || "https://sdn.myfolie.com";
  const host_prefix = product.attributes ? SHOP_API_URL : "";
  const imgSrcs = product.thumbnail
    ? `${host_prefix}${product.thumbnail.img_url} ${
        product.thumbnail.img_size || "640w"
      }`
    : "";
  // var webpImgSrcs  = `${product.thumbnail.webp_url} ${product.thumbnail.webp_size}`;
  //               { srcSet: webpImgSrcs, type: "image/webp" },

  // <Picture
  // className="card-img-top"
  // sources={[
  //   { srcSet: imgSrcs, type: "image/jpeg" }
  // ]}
  // sizes="(min-width: 87.5em) calc(466.66px - 30px),(min-width: 75em) calc(33.33vw - 30px), (min-width: 62em) calc(360px - 30px), (min-width: 48em) calc(540px - 30px), (min-width: 36em) calc(100vw - 30px)"
  // alt={product.attributes ? product.attributes.name : product.title}
  // loading="lazy" />

  return (
    <Card
      className={`shop-product-card h-100 ${
        selected === product.id ? "selected" : ""
      }`}
      onClick={
        onClickOverwrite
          ? () => handleProductClickContactForm(product.id)
          : (event) => handleProductClick(event)
      }
    >
      {!!product.external && (
        <a href={product.external}>
          <LazyLoad>
            <ResponsivePicture
              mediumData={{
                url: imgSrcs,
                metadata: {
                  width: 640,
                  height: 640,
                },
              }}
              className="card-img-top"
              altTitle={
                product.attributes ? product.attributes.name : product.title
              }
            />
          </LazyLoad>
        </a>
      )}
      {!product.external && (
        <Link
          to={`${onClickOverwrite ? "#" : ""}${
            product?.internal
              ? product.internal
              : `/${language}/i/${
                  product && product.attributes
                    ? product.attributes.slug
                    : product.id
                }/`
          }`}
        >
          <LazyLoad>
            <ResponsivePicture
              mediumData={{
                url: imgSrcs,
                metadata: {
                  width: 640,
                  height: 640,
                },
              }}
              className="card-img-top"
              altTitle={
                product.attributes ? product.attributes.name : product.title
              }
            />
            {/* <Picture
              className="card-img-top"
              sources={[{ srcSet: imgSrcs, type: "image/jpeg" }]}
              sizes="(min-width: 87.5em) calc(466.66px - 30px),(min-width: 75em) calc(33.33vw - 30px), (min-width: 62em) calc(360px - 30px), (min-width: 48em) calc(540px - 30px), (min-width: 36em) calc(100vw - 30px)"
              alt={product.attributes ? product.attributes.name : product.title}
              loading="lazy"
            /> */}
          </LazyLoad>
        </Link>
      )}
      <CardBody
      // onClick={
      //   onClickOverwrite
      //     ? () => handleProductClickContactForm(product.id)
      //     : handleProductClick()
      // }
      >
        <CardTitle className="h5" tag={titleTag}>
          {product.attributes ? product.attributes.name : product.title}
        </CardTitle>
        <CardSubtitle>
          {!!product.saleprice && (
            <span className="sale-price">
              {context.t("from") + " "}
              <FormattedNumber
                value={parseFloat(product.saleprice / 100)}
                language={language}
                unit="€"
                decimals={2}
              />
              <s>
                <FormattedNumber
                  value={parseFloat(
                    product.attributes
                      ? product.attributes.price
                      : product.price
                  )}
                  language={language}
                  unit="€"
                  decimals={2}
                />
              </s>
            </span>
          )}

          {(!product.saleprice || product.saleprice === 0) && (
            <>
              {context.t("from") + " "}
              <FormattedNumber
                value={parseFloat(
                  product.attributes ? product.attributes.price : product.price
                )}
                language={language}
                unit="€"
                decimals={2}
              />
            </>
          )}
          {!!product.basicprice && (
            <div className="text-remark">
              ({context.t("from")}{" "}
              <FormattedNumber
                className="basic-price"
                value={parseFloat(product.basicprice.price / 100)}
                language={language}
                unit={`€ / ${
                  product.basicprice.unit ? product.basicprice.unit : "m²"
                }`}
                decimals={2}
              />
              )
            </div>
          )}
          {!product.basicprice && (
            <div className="text-remark">
              <br />
            </div>
          )}
        </CardSubtitle>
        {/* <CardText>Some text? price maybe?</CardText> */}
      </CardBody>
    </Card>
  );
};
/**  define proptype for the 'translation' function  */
ShopProductCard.contextTypes = {
  t: PropTypes.func,
};

ShopProductCard.propTypes = {
  navigateTo: PropTypes.func,
  titleTag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

export default ShopProductCard;
